import React from 'react';
import Grid from '@mui/material/Grid2';
import './styles/contact.scss';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { AutocompleteAddressField } from '../../../layout/forms/AutocompleteAddressField';

export const Contact = () => {
  return (
    <Grid className='contact-main-container'>
      <Typography variant='h3'>ESTAMOS PARA ESCUCHARTE</Typography>
      <Typography variant='h4'>POR FAVOR ESCRIBENOS</Typography>
      <Grid className='contact-form-container'>
        <Grid className='form-line'>
          <Grid>
            <TextField 
              id="name" 
              label="Nombre Completo" 
              variant="outlined" 
              autoComplete='off' 
              sx={{ width: '340px', m: '0px 10px' }} 
            />
          </Grid>
          <Grid>
            <MuiPhoneNumber variant='outlined' label="Telefono" defaultCountry={'mx'} onChange={e => console.log(e)} sx={{ width: '210px', mr: '10px' }} />
          </Grid>
        </Grid>  
        <Grid className='form-line'>
          <Grid>
            <TextField 
              id="email" 
              label="Email" 
              variant="outlined" 
              autoComplete='off' 
              type='email'
              sx={{ width: '330px', m: '0px 10px'}} 
            />
          </Grid>
          <Grid>
            <FormControl sx={{ width: '220px', mr: '10px' }}>
              <InputLabel id="demo-simple-select-autowidth-label">¿Cual tipo de usuario te identifica?</InputLabel>
              <Select
                label='¿Cual tipo de usuario te identifica?'
                labelId="users-label"
                id="users"
                value={'Recidente'}
                onChange={e => console.log(e)}
                autoWidth
                defaultValue='Recidente'

              >
                <MenuItem value={'Recidente'}>Recidente</MenuItem>
                <MenuItem value={'Administrador'}>Administrador</MenuItem>
                <MenuItem value={'Empresa'}>Empresa</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>  
        <Grid className='form-line'>
          <TextField 
            id="text" 
            label="Por favor cuentanos ¿Como Podemos Ayudarte?" 
            variant="outlined" 
            autoComplete='off' 
            multiline
            rows={5}
            sx={{ width: '560px' }} 
          />
        </Grid>  
        <Grid className='form-line'>
          <AutocompleteAddressField label='¿De donde nos consultas?' width={560} />
        </Grid>  
        <Grid className='submit-form-btn'>
          <Button type='button' variant="outlined" color='primary'>ENVIAR</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
