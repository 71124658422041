import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Auth {
  logged: boolean;
  name: string;
}

const initialState = {
  auth: {
    logged: false,
    name: '',
  } as Auth,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin(state, action: PayloadAction<Auth>) {
      state.auth = action.payload;
    },
    setLogout(state) {
      state.auth = initialState.auth;
    },
  }
});

export const {
  setLogin,
  setLogout,
} = authSlice.actions;

export default authSlice.reducer;