import React from 'react';
interface Props {
  size?: number;
}

export const CircleLogoIcon = ({ size = 200 }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" width={size} height={size}>
      <defs>
        <linearGradient id="Degradado_sin_nombre_5" data-name="Degradado sin nombre 5" x1="497.24" y1="240.83" x2="497.24" y2="268.58" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fff"/>
          <stop offset=".05" stop-color="#ececec"/>
          <stop offset=".26" stop-color="#a5a5a5"/>
          <stop offset=".45" stop-color="#6a6a6a"/>
          <stop offset=".63" stop-color="#3c3c3c"/>
          <stop offset=".78" stop-color="#1b1b1b"/>
          <stop offset=".91" stop-color="#070707"/>
          <stop offset="1" stop-color="#000"/>
        </linearGradient>
        <linearGradient id="Degradado_sin_nombre_5-2" data-name="Degradado sin nombre 5" x1="497.34" y1="284.5" x2="497.34" y2="300.44" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-3" data-name="Degradado sin nombre 5" x1="497.17" y1="317.01" x2="497.17" y2="337.05" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-4" data-name="Degradado sin nombre 5" x1="497.21" y1="353.52" x2="497.21" y2="451.47" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-5" data-name="Degradado sin nombre 5" x1="514.34" y1="255" x2="514.34" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-6" data-name="Degradado sin nombre 5" x1="479.08" y1="248.23" x2="479.08" y2="374.98" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-7" data-name="Degradado sin nombre 5" x1="479.08" y1="391.22" x2="479.08" y2="451.45" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-8" data-name="Degradado sin nombre 5" x1="460.26" y1="281.1" x2="460.26" y2="350.36" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-9" data-name="Degradado sin nombre 5" x1="460.26" y1="366.24" x2="460.26" y2="451.48" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-10" data-name="Degradado sin nombre 5" x1="532.12" y1="300.92" x2="532.12" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-11" data-name="Degradado sin nombre 5" x1="563.04" y1="417.42" x2="563.04" y2="451.45" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-12" data-name="Degradado sin nombre 5" x1="556.3" y1="326.88" x2="556.3" y2="451.47" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-13" data-name="Degradado sin nombre 5" x1="581.14" y1="356.9" x2="581.14" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-14" data-name="Degradado sin nombre 5" x1="599.85" y1="385.21" x2="599.85" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-15" data-name="Degradado sin nombre 5" x1="633" y1="387.18" x2="633" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-16" data-name="Degradado sin nombre 5" x1="625.5" y1="303.95" x2="625.5" y2="451.47" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-17" data-name="Degradado sin nombre 5" x1="651.94" y1="346.21" x2="651.94" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-18" data-name="Degradado sin nombre 5" x1="671.62" y1="376.17" x2="671.62" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-19" data-name="Degradado sin nombre 5" x1="691.97" y1="356.88" x2="691.97" y2="386.75" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-20" data-name="Degradado sin nombre 5" x1="691.97" y1="402.75" x2="691.97" y2="451.44" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-21" data-name="Degradado sin nombre 5" x1="713.3" y1="401.4" x2="713.3" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-22" data-name="Degradado sin nombre 5" x1="734.04" y1="416.7" x2="734.04" y2="451.43" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-23" data-name="Degradado sin nombre 5" x1="442.17" y1="407.56" x2="442.17" y2="451.95" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-24" data-name="Degradado sin nombre 5" x1="442.17" y1="350.55" x2="442.17" y2="391.22" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-25" data-name="Degradado sin nombre 5" x1="435.93" y1="318.75" x2="435.93" y2="451.94" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-26" data-name="Degradado sin nombre 5" x1="410.49" y1="356.21" x2="410.49" y2="450.73" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-27" data-name="Degradado sin nombre 5" x1="386.49" y1="303.73" x2="386.49" y2="451.21" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-28" data-name="Degradado sin nombre 5" x1="362.49" y1="357.21" x2="362.49" y2="451.73" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-29" data-name="Degradado sin nombre 5" x1="344.49" y1="385.36" x2="344.49" y2="451.58" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-30" data-name="Degradado sin nombre 5" x1="326.49" y1="346.86" x2="326.49" y2="452.08" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-31" data-name="Degradado sin nombre 5" x1="308" y1="379.55" x2="308" y2="450.73" xlinkHref="#Degradado_sin_nombre_5"/>
        <linearGradient id="Degradado_sin_nombre_5-32" data-name="Degradado sin nombre 5" x1="288.29" y1="404.47" x2="288.29" y2="450.73" xlinkHref="#Degradado_sin_nombre_5"/>
      </defs>
      <g id="edificios">
        <circle cx="607.5" cy="265.5" r="10.5" fill="#fff;"/>
        <path d="M497.39,268.42c-.39-9.42-.39-18.42,0-27.42" fill='none' stroke='url(#Degradado_sin_nombre_5)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M497.29,300.42c.03-5.3.06-10.6.1-15.9" fill='none' stroke='url(#Degradado_sin_nombre_5-2)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M497.13,337.04c.02-6.67.05-13.34.08-20.01" fill='none' stroke='url(#Degradado_sin_nombre_5-3)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M497.39,451.43c-.39-32.43-.39-65.43-.3-97.89" fill='none' stroke='url(#Degradado_sin_nombre_5-4)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="514.34" y1="451.43" x2="514.34" y2="255" fill='none' stroke='url(#Degradado_sin_nombre_5-5)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M479.12,374.97c-.12-41.97-.12-84.97,0-126.73" fill='none' stroke='url(#Degradado_sin_nombre_5-6)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M479.12,451.43c-.12-20.43-.12-40.43,0-60.18" fill='none' stroke='url(#Degradado_sin_nombre_5-7)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M460.42,350.29c-.42-23.29-.42-46.29,0-69.12" fill='none' stroke='url(#Degradado_sin_nombre_5-8)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M460.42,451.43c-.42-28.43-.42-57.43,0-85.13" fill='none' stroke='url(#Degradado_sin_nombre_5-9)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="532.12" y1="451.43" x2="532.12" y2="300.92" fill='none' stroke='url(#Degradado_sin_nombre_5-10)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M563.07,417.45c-.07,10.55-.07,22.55,0,33.98" fill='none' stroke='url(#Degradado_sin_nombre_5-11)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M549.53,451.43c.47-40.43.47-79.43.47-119.43,4,1,9-1,13,1,0,23,0,45,.07,68.04" fill='none' stroke='url(#Degradado_sin_nombre_5-12)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="581.14" y1="451.43" x2="581.14" y2="356.9" fill='none' stroke='url(#Degradado_sin_nombre_5-13)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="599.85" y1="451.43" x2="599.85" y2="385.21" fill='none' stroke='url(#Degradado_sin_nombre_5-14)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M633,387.18v64.24" fill='none' stroke='url(#Degradado_sin_nombre_5-15)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M618.37,451.43c-.37-36.43-.37-73.43-.37-109.43,6-7,9-16,15-23.95,0,11.67-.19.3,0,0v52.79" fill='none' stroke='url(#Degradado_sin_nombre_5-16)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="651.94" y1="451.43" x2="651.94" y2="346.21" fill='none' stroke='url(#Degradado_sin_nombre_5-17)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="671.62" y1="451.43" x2="671.62" y2="376.17" fill='none' stroke='url(#Degradado_sin_nombre_5-18)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M691.95,386.73c.05-9.73.05-19.73,0-29.83" fill='none' stroke='url(#Degradado_sin_nombre_5-19)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M691.95,451.43c.05-16.43.05-32.43,0-48.66" fill='none' stroke='url(#Degradado_sin_nombre_5-20)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="713.3" y1="451.43" x2="713.3" y2="401.4" fill='none' stroke='url(#Degradado_sin_nombre_5-21)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="734.04" y1="451.43" x2="734.04" y2="416.7" fill='none' stroke='url(#Degradado_sin_nombre_5-22)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M442.07,407.56c.01,14.79.08,29.57.19,44.36" fill='none' stroke='url(#Degradado_sin_nombre_5-23)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M442.26,350.58c-.1,13.54-.17,27.09-.19,40.63" fill='none' stroke='url(#Degradado_sin_nombre_5-24)' strokeMiterlimit='10' strokeWidth='8px'/>
        <path d="M428.72,451.92c.28-42.92.28-85.92.28-128.92,4,0,9-1,13,1,3,2-1,7,.26,10.54" fill='none' stroke='url(#Degradado_sin_nombre_5-25)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="410.49" y1="450.73" x2="410.49" y2="356.21" fill='none' stroke='url(#Degradado_sin_nombre_5-26)' strokeMiterlimit='10' strokeWidth='8px'/>
        <polyline points="393.81 451.21 393.81 341.53 379.18 317.83 379.18 451.21" fill='none' stroke='url(#Degradado_sin_nombre_5-27)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="362.49" y1="451.73" x2="362.49" y2="357.21" fill='none' stroke='url(#Degradado_sin_nombre_5-28)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="344.49" y1="451.58" x2="344.49" y2="385.36" fill='none' stroke='url(#Degradado_sin_nombre_5-29)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="326.49" y1="452.08" x2="326.49" y2="346.86" fill='none' stroke='url(#Degradado_sin_nombre_5-30)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="308" y1="450.73" x2="308" y2="379.55" fill='none' stroke='url(#Degradado_sin_nombre_5-31)' strokeMiterlimit='10' strokeWidth='8px'/>
        <line x1="288.29" y1="450.73" x2="288.29" y2="404.47" fill='none' stroke='url(#Degradado_sin_nombre_5-32)' strokeMiterlimit='10' strokeWidth='8px'/>
      </g>
      <g id="text">
        <path d="M459.48,451.43h-92.65" fill='#fff' stroke='#fff' strokeMiterlimit='10' strokeWidth='.25px'/>
        <path d="M556.35,451.43h-58.96" fill='#fff' stroke='#fff' strokeMiterlimit='10' strokeWidth='.25px'/>
        <path d="M632.4,451.43h-41.25" fill='#fff' stroke='#fff' strokeMiterlimit='10' strokeWidth='.25px'/>
        <g>
          <path d="M213.88,512.51v20.23c0,1.11-.9,2-2,2h-9.53c-1.11,0-2-.9-2-2v-61.61h31.34c10.31,0,18.97,7.83,18.9,19.69,0,8.16-4.04,14.36-9.87,17.47-1.04.56-1.48,1.8-.89,2.82l12.09,20.62c.78,1.34-.18,3.02-1.73,3.02h-10.53c-.75,0-1.44-.42-1.78-1.09l-11.27-22.05c-.34-.67-1.03-1.09-1.78-1.09h-8.93c-1.11,0-2,.9-2,2ZM230.59,499.34c3.63,0,6.92-3.02,6.92-8.54.03-5.43-3.26-8.47-6.92-8.47h-14.71c-1.11,0-2,.9-2,2v13.01c0,1.11.9,2,2,2h14.71Z" fill='#fff'/>
          <path d="M298.39,534.74h-34.88v-63.61h33.97c6.18,0,11.19,5.01,11.19,11.19h0s-31.62,0-31.62,0v13.51h28.08v11.2h-28.08v16.51h32.53c0,6.18-5.01,11.2-11.2,11.2Z" fill='#fff'/>
          <path d="M351.46,469.86c8.13,0,17.28,3.07,25.07,9.02l-3.48,9.56c-6.77-4.35-14.22-6.67-20.79-6.66-11.14-.03-19.13,7.84-19.13,21.15s8.01,21.19,19.17,21.19c6.62,0,14.18-2.43,21.02-6.87l3.47,9.54c-7.84,6.05-17.19,9.21-25.34,9.21-19.42,0-32.23-14.78-32.23-33.08s12.81-33.14,32.23-33.08h0Z" fill='#fff'/>
          <path d="M389.59,527.97v-50.07c0-3.74,3.03-6.77,6.77-6.77h0c3.74,0,6.77,3.03,6.77,6.77v50.07c0,3.74-3.03,6.77-6.77,6.77h0c-3.74,0-6.77-3.03-6.77-6.77Z" fill='#fff'/>
          <path d="M443.69,471.13c18.92,0,31.38,13.51,31.38,31.8s-12.46,31.81-31.38,31.81h-25.47v-63.61h25.47ZM442.6,523.55c11.43,0,18.69-8.08,18.69-20.61s-7.27-20.6-18.69-20.6h-10.84v41.21h10.84Z" fill='#fff'/>
          <path d="M523.63,534.74h-34.88v-63.61h33.97c6.18,0,11.19,5.01,11.19,11.19h0s-31.62,0-31.62,0v13.51h28.08v11.2h-28.08v16.51h32.53c0,6.18-5.01,11.2-11.2,11.2Z" fill='#fff'/>
          <path d="M601.85,534.74h-12.1l-30.56-41.64v41.64h-13.18v-63.61h12.09l30.56,41.98v-28.79c0-7.28,5.91-13.19,13.19-13.19h0v63.61Z" fill='#fff'/>
          <path d="M654.53,486.99h0c-3.82-3.06-9.24-5.29-15.92-5.3-6.06,0-10.22,1.89-10.5,7.31-.16,3.55,1.62,4.79,12.79,7.8,9.59,2.59,22.18,5.58,22.27,19.34.09,14.29-13.65,19.89-24.97,19.89-9.72,0-20.3-4.09-25.62-10.9l7.62-8.53c3.97,4.87,11.45,7.69,18,7.67,7,.02,11.01-3.13,11.06-7.67.09-4.76-3.82-5.72-12.69-8.18-15.15-4.22-22.35-7.42-22.38-18.72.02-14.37,11.79-19.81,24.53-19.81,4.01,0,7.74.55,11.13,1.54,6.66,1.96,9.06,10.2,4.68,15.59Z" fill='#fff'/>
          <path d="M692.84,511.82l-23.55-40.68h0c9.38,0,18.03,5.07,22.6,13.27l7.87,14.14M699.77,498.55l15.32-27.42h15.2s-23.91,40.62-23.91,40.62v16.57c0,3.55-2.88,6.43-6.43,6.43h-7.12v-22.92" fill='#fff'/>
          <path d="M775.69,486.99h0c-3.82-3.06-9.24-5.29-15.92-5.3-6.06,0-10.22,1.89-10.5,7.31-.16,3.55,1.62,4.79,12.79,7.8,9.59,2.59,22.18,5.58,22.27,19.34.09,14.29-13.65,19.89-24.97,19.89-9.72,0-20.3-4.09-25.62-10.9l7.62-8.53c3.97,4.87,11.45,7.69,18,7.67,7,.02,11.01-3.13,11.06-7.67.09-4.76-3.82-5.72-12.69-8.18-15.15-4.22-22.35-7.42-22.38-18.72.02-14.37,11.79-19.81,24.53-19.81,4.01,0,7.74.55,11.13,1.54,6.66,1.96,9.06,10.2,4.68,15.59Z" fill='#fff'/>
        </g>
        <polygon points="790.53 451.43 647.46 451.43 623.37 427.34 790.53 451.43" fill='#fff'/>
        <polygon points="790.54 553.8 653.48 553.8 629.39 577.88 790.54 553.8" fill='#fff'/>
        <polygon points="194.17 553.8 334.32 553.8 358.41 577.88 194.17 553.8" fill='#fff'/>
        <path d="M373.46,556.81h6.02-6.02Z" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="358.41" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="629.39" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="376.47" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="611.32" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="394.54" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="593.26" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="412.61" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="575.19" cy="553.8" r="3.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="434.61" cy="554.79" r="4.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="553.54" cy="555.37" r="4.01" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="464.54" cy="552.58" r="5.27" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="523.49" cy="553.85" r="5.27" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <circle cx="493.38" cy="553.85" r="5.27" fill='#fff' stroke='#fff' strokeMiterlimit='10'/>
        <polyline points="364.43 427.34 194.17 451.43 340.34 451.43 364.43 427.34 340.34 451.43" fill='#fff'/>
      </g>
      <g id="circle">
        <path d="M790.53,451.43c-24.68-142.25-148.73-250.43-298.03-250.43s-273.35,108.17-298.03,250.43" fill='none' stroke='#fff' strokeMiterlimit='10'/>
        <path d="M194.17,553.85c23.97,143.1,148.42,252.15,298.33,252.15s273.37-108.19,298.04-250.46" fill='none' stroke='#fff' strokeMiterlimit='10'/>
      </g>
    </svg>
  );
};
