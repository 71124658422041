import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import { KNOW_US_TEXT_P1, KNOW_US_TEXT_P2, KNOW_US_TEXT_P3 } from '../../../static/text';

import './styles/knowus.scss';

export const KnowUs = () => {
  return (
    <Grid className="knowus-main-container">
      <Typography className='knowus-title'>PEMITENOS PRESENTARNOS</Typography>
      <Typography className='knowus-text'>
        <p>{KNOW_US_TEXT_P1}</p>
        <p>{KNOW_US_TEXT_P2}</p>
        <p>{KNOW_US_TEXT_P3}</p>
      </Typography>
    </Grid>
  );
};
