import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useLocation } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LogoIcon } from '../../static/icons/LogoIcon';
import './styles/main.scss';

interface Props {
  setSearchOpen: Dispatch<SetStateAction<boolean>>;
}

export const NavBar = ({ setSearchOpen }: Props) => {
  const [isTransparent, setIsTransparent] = useState(true);
  const { pathname } = useLocation();
  useEffect(() => {
      const handleScroll = () => {
          if (window.scrollY > 75) {
              setIsTransparent(false);
          } else {
              setIsTransparent(true);
          }
      };
      window.addEventListener('scroll', handleScroll);
      // Limpiar el evento cuando el componente se desmonta
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);
  return (
    <Grid className={`nav-bar ${isTransparent && pathname.includes('/home') ? 'navbar-transparent' : 'navbar-black '} `}>
      <Grid className='nav-bar-container'>
        <Grid>
          <a href="/home"><LogoIcon /></a>
        </Grid>
        <ul>
          <li><a href="/plans">PLANES</a></li>
          <li><a href="/products">PRODUCTOS</a></li>
          <li><a href="/knowus">CONOCENOS</a></li>
          <li><a href="/contact">CONTACTO</a></li>
          <IconButton onClick={() => setSearchOpen(open => !open)}><SearchIcon /></IconButton>
        </ul>
        <a href='/auth'>
          <Button 
            type='button' 
            variant='outlined' 
            className='login-btn'
          >
            LOG IN
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};
