import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom';
import { Auth } from '../layout/auth/Auth';
import { MainComercialLayout } from '../layout/comercialLayout/MainComercialLayout';
import { PasswordReset } from '../layout/auth/PasswordReset';
import { CreateUser } from '../layout/auth/CreateUser';
import { MainApplicationLayout } from '../layout/applicationLayout/MainApplicationLayout';

export const MainRoutes = () => {
  const location = useLocation();
  return (
      <Switch location={location} key={location.pathname}>
        <Route exact path='/auth'>
          <Auth />
        </Route>
        <Route exact path='/accounts/reset'>
          <PasswordReset />
        </Route>
        <Route exact path='/accounts/register'>
          <CreateUser />
        </Route>
        <Route exact path='/auth/*'>
          <MainApplicationLayout />
        </Route>
        <Route path='/*'>
          <MainComercialLayout />
        </Route>
      </Switch>
  );
};