import React from 'react';
import Grid from '@mui/material/Grid2';
import { CircleLogoIcon } from '../../static/icons/CircleLogoIcon';
import { Button, TextField, Typography } from '@mui/material';
import './auth.scss';

export const Auth = () => {

  return (
    <Grid className='auth-main-container'>
      <Grid className='main-auth'>
        <Grid className='auth-form'>
          <Grid sx={{ mt: -2, mb: -3, ml: 1.7 }}>
            <CircleLogoIcon size={260} />
          </Grid>
          <TextField 
            id="auth-email" 
            label={<Typography sx={{ color: '#b8b8b8', fontSize: '13px' }}>Email</Typography>}
            variant="filled" 
            autoComplete='off' 
            type='email'
            sx={{ width: '300px', marginBottom: '10px' }} 
            inputProps={{
              style: { height: '15px', color: 'white', fontSize: '14px', backgroundColor: 'black', borderBottom: 'solid white 1px' },
            }}
          />
          <TextField 
            id="auth-password" 
            label={<Typography sx={{ color: '#b8b8b8', fontSize: '13px' }}>Contraseña</Typography>}
            variant="filled" 
            autoComplete='off' 
            type='password'
            sx={{ width: '300px', m: '0px 10px', marginBottom: '20px' }} 
            inputProps={{
              style: { height: '15px', color: 'white', fontSize: '14px', backgroundColor: 'black', borderBottom: 'solid white 1px' },
            }}
          />
          <a href='/auth/main' >
            <Button 
              type='button' 
              variant='contained' 
              sx={{ width: '305px', height: '33px', borderRadius: '9px', backgroundColor: 'primary.main' }}
            >
              INICIAR SESIÓN
            </Button>
          </a>
          <Typography sx={{ fontSize: '12px', mt: '17px', mb: '20px', cursor: 'pointer', textDecoration: 'none' }}>
            <a style={{ textDecoration: 'none', color: 'white' }} href='/accounts/reset'>¿Olvidaste tu Contraseña?</a>
          </Typography>
          <Typography sx={{ display: 'flex', fontSize: '14px', mt: '55px' }}>
            ¿No tienes una cuenta?
            <a href='/accounts/register' style={{ textDecoration: 'none' }}>
              <Typography sx={{ ml: 1, color: 'primary.light', cursor: 'pointer', fontSize: '14px' }}>
                  Crear cuenta
              </Typography>
            </a>
          </Typography>
        </Grid>
        <Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
