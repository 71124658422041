import { combineReducers, configureStore } from "@reduxjs/toolkit";
import themeReducer from './themeSlice';
import authReducer from './authSlice';

const reducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
});

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;