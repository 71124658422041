import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';

const listItemBtnStyles = (open: boolean) => [
  {
    height: 40,
    px: 2.5,
  },
  open
    ? {
        ml: -0.96,
        alignItems: 'center',
        justifyContent: 'initial',
      }
    : {
        width: 49,
        ml: 0,
        justifyContent: 'center',
      },
];
const listItemIconStyles = (open: boolean) => [
  {
    minWidth: 0,
    justifyContent: 'center',
  },
  open
    ? {
        mr: 1,
      }
    : {
        mr: 'auto',
      },
];
const listItemTextStyles = (open: boolean) => [
  open
    ? {
        opacity: 1,
      }
    : {
        opacity: 0,
      },
];

interface Props {
  isOpen: boolean;
  icon: any;
  label: string;
  onClick: () => void;
}

export const ListItemComponent = ({ isOpen, icon, label, onClick }: Props) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setIsTooltipActive(isOpen);
      }, 500);
      return;
    }
    setIsTooltipActive(isOpen);
  }, [isOpen]);
  return (
    <ListItem key='admin-dashboard' disablePadding sx={{ display: 'block' }}>
      <Tooltip 
        title={!isTooltipActive ? label : null} 
        placement='right'
      >
        <ListItemButton sx={listItemBtnStyles(isOpen)} onClick={onClick}>
          <ListItemIcon sx={listItemIconStyles(isOpen)}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={label}
            sx={listItemTextStyles(isOpen)}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};
