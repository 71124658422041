import React, { Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Drawer, FormControlLabel, Switch, Typography } from '@mui/material';
import PortraitIcon from '@mui/icons-material/Portrait';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setThemeAspect } from '../../../redux/themeSlice';

interface Props {
  setConfOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

export const ConfigutationsDrawer = ({ open, setConfOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { aspect } = useAppSelector(state => state.theme);
  return (
    <Drawer 
      open={open} 
      onClose={() => setConfOpen(false)} 
      anchor='top'
    >
      <Box 
        sx={{ height: '300px', backgroundColor: 'background.default', mt: '45px' }}
      >
        <Grid sx={{ width: '90%', margin: 'auto' }}>
          <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }}>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
             <PortraitIcon sx={{ mr: 1.5, fontSize: '35px' }} />
              Carlos Yair Larracilla Espinosa
            </Typography>
            <FormControlLabel 
              sx={{ display: 'flex', justifyContent: 'left', ml: 6, width: '100px' }}
              control={<Switch 
                icon={<Brightness7Icon sx={{ color: 'orange', backgroundColor: 'primary.light', borderRadius: '50%', border: '0px transparent' }} />}
                checkedIcon={<NightlightRoundIcon sx={{ backgroundColor: 'primary.light', borderRadius: '50%', border: '0px transparent' }} />}
                sx={{ ml: 1.5 }}
                checked={aspect}
                onChange={e => {
                  dispatch(setThemeAspect(e.target?.checked));
                  const themeLocalStorage = localStorage.getItem('recidensysUserThemePreferences');
                  if (themeLocalStorage) {
                    const storageData = JSON.parse(themeLocalStorage);
                    const userPreferences = {
                      ...storageData,
                      aspect: e.target?.checked,
                    };
                    localStorage.setItem('recidensysUserThemePreferences', JSON.stringify(userPreferences));
                  } else {
                    const userPreferences = {
                      aspect: e.target?.checked,
                    };
                    localStorage.setItem('recidensysUserThemePreferences', JSON.stringify(userPreferences));
                  }
                }}
              />}
              label='Aspecto'
              labelPlacement='start'
            />
          </Box>
        </Grid>
      </Box>
    </Drawer>
  )
}
