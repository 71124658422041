import React from 'react';
import Grid from '@mui/material/Grid2';
import { LogoIcon } from '../../static/icons/LogoIcon';
import './auth.scss';
import { Button, Card, CardContent, CardHeader, Divider, TextField, Typography } from '@mui/material';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

export const CreateUser = () => {
  return (
    <Grid className='create-user-main-container'>
      <Grid className='main-create-user'>
        <Grid className='create-user-logo'>
          <LogoIcon size={110} />
        </Grid>
        <Card className='create-user-form'>
          <CardHeader
            sx={{ ml: 4 }}
            avatar={
              <PersonAddAltOutlinedIcon />
            }
            title={<Typography sx={{ fontWeight: 500 }}>Crear Usuario</Typography>}
          />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', width: '300px', m: 'auto' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'center' }}>
              Crea tu usuario para acceder a la plataforma.
            </Typography>
            <TextField 
              label='Nombre completo'
              sx={{ width: '100%', mt: 2.5 }}
              size='small' 
              autoComplete='off'
            />
            <TextField 
              label='Email'
              sx={{ width: '100%', mt: 2.5 }}
              size='small' 
              type='email'
              autoComplete='off'
            />
            <TextField 
              label='Vuelve a introducir tu email'
              sx={{ width: '100%', mt: 1.5 }}
              size='small' 
              type='email'
              autoComplete='off'
            />
            <TextField 
              label='Codigo del producto'
              sx={{ width: '100%', mt: 1.5 }}
              size='small' 
              type='password'
              autoComplete='off'
            />
            <TextField 
              label='Contraseña'
              sx={{ width: '100%', mt: 1.5 }}
              size='small' 
              type='password'
              autoComplete='off'
            />
            <TextField 
              label='Vuelve a introducir tu contraseña'
              sx={{ width: '100%', mt: 1.5 }}
              size='small' 
              type='password'
              autoComplete='off'
            />
            <Button variant='contained' sx={{ mt: 2, height: '30px', borderRadius: 2, mb: 4, backgroundColor: 'primary.main' }}>Crear Cuenta</Button>
            <Divider variant='fullWidth'>O</Divider>
            <Typography sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
              <a 
                href='/auth' 
                style={{ textDecoration: 'none', fontSize: '15px', cursor: 'pointer', color: '#363636', display: 'flex' }}
              >
                <LoginOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                Iniciar sesión
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
