import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation } from "@apollo/client";
import { lowerEnv } from "../utils/utils";
import initCache from './cache'

let client: ApolloClient<any>;

const recidensysQueryLink = (env: string) => {
  return new HttpLink({
    uri: process.env.REACT_APP_RECIDENSYS_QUERY_SERVICE_URL?.replace('dev', env),
    credentials: 'include',
  });
};

const recidensysMutationLink = (env: string) => {
  return new HttpLink({
    uri: process.env.REACT_APP_RECIDENSYS_MUTATION_SERVICE_URL?.replace('dev', env),
    credentials: 'include',
  });
};

export const getApolloClient = (): ApolloClient<any> => {
  if (client) return client;
  const cache: InMemoryCache = initCache();
  const apolloClient: ApolloClient<any> = new ApolloClient({
    link: ApolloLink.split(
      (operation: Operation) => operation.getContext().LinkName === process.env.REACT_APP_RECIDENSYS_QUERY_LINK_NAME,
      recidensysQueryLink(lowerEnv()),
      ApolloLink.split(
        (operation: Operation) => operation.getContext().LinkName === process.env.REACT_APP_RECIDENSYS_MUTATION_LINK_NAME,
        recidensysMutationLink(lowerEnv()),
      ),
    ),
    cache,
    credentials: 'include',
  });
  return apolloClient
};