import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AdminScreen } from '../../views/application/admin/AdminScreen';

const basePath = '/auth'

export const ApplicationOutlet = () => {
  const location = useLocation();
  return (
    <Switch location={location} key={location.pathname}>
      <Route path={`${basePath}/main`}>
        <AdminScreen />
      </Route>
      <Route path={`${basePath}/*`}>
        <Redirect to={`${basePath}/main`} />
      </Route>
    </Switch>
  );
};
