import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { getApolloClient } from './apollo';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { MainRoutes } from './routes/MainRoutes';

function App() {
  const client = getApolloClient();
  const history = createBrowserHistory();
  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <MainRoutes />
      </Router>
    </ApolloProvider>
  );
}

export default App;
