import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#033263',      // Color principal (usualmente azul)
            light: '#D4EDFC',     // Opcional: versión clara del color primario
            dark: '#072246',      // Opcional: versión oscura del color primario
            contrastText: '#fff'  // Opcional: color del texto sobre el color primario
        },
        secondary: {
            main: '#d32f2f',      // Color secundario 
            light: '#ff6659',     // Opcional: versión clara del color secundario
            dark: '#9a0007',      // Opcional: versión oscura del color secundario
            contrastText: '#fff'  // Opcional: color del texto sobre el color secundario
        },
        // Configuración opcional de fondo o tipo de tema
        background: {
            default: '#F5F5F5',   // Fondo de la página
            paper: '#fff'         // Fondo de los elementos como tarjetas
        },
        mode: 'light',           // 'light' o 'dark' para definir el tema base
    }
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#1E1F23',      // Color principal (usualmente azul)
            light: '#535B5E',     // Opcional: versión clara del color primario
            dark: '#000',      // Opcional: versión oscura del color primario
            contrastText: '#fff'  // Opcional: color del texto sobre el color primario
        },
        secondary: {
            main: '#d32f2f',      // Color secundario (usualmente rojo)
            light: '#ff6659',     // Opcional: versión clara del color secundario
            dark: '#9a0007',      // Opcional: versión oscura del color secundario
            contrastText: '#fff'  // Opcional: color del texto sobre el color secundario
        },
        // Configuración opcional de fondo o tipo de tema
        background: {
            default: '#1F1F1F',   // Fondo de la página
            paper: '#1F1F1F'         // Fondo de los elementos como tarjetas
        },
        mode: 'dark',           // 'light' o 'dark' para definir el tema base
    }
});