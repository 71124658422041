import React, { Dispatch, SetStateAction, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Drawer, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './styles/main.scss'

interface Props {
  setSearchOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

export const SearchNavbar = ({ open, setSearchOpen }: Props) => {
  const textF = useRef<any>();
  setTimeout(() => {
    textF.current?.focus();
  }, 1);
  
  return (
    <Drawer 
      open={open} 
      onClose={() => setSearchOpen(false)} 
      anchor='right'
      className='nav-search-drawer'
    >
      <Box 
        className='nav-search-container' 
      >
        <Grid className='search-bar-form'>
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <SearchIcon sx={{ mr: 1, fontSize: '25px', color: '#979a9a' }} />
            <TextField 
            inputRef={textF}
              id="standard-basic" 
              variant="standard" 
              className='search-field' 
              autoComplete='off' 
              inputProps={{
                style: { height: '25px', color: 'white', fontSize: '22px' },
              }}
              placeholder='Buscar'
            />
          </Box>

        </Grid>
      </Box>
    </Drawer>
  );
};
