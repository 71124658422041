import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Theme {
  aspect: boolean;
}

const initialState: Theme = {
  aspect: false,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeAspect(state, action: PayloadAction<boolean>) {
      state.aspect = action.payload;
    },
  }
});

export const {
  setThemeAspect,
} = themeSlice.actions;

export default themeSlice.reducer;