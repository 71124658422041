import React from 'react';
import Grid from '@mui/material/Grid2';
import './styles/main.scss';
import { Typography } from '@mui/material';
import { CircleLogoIcon } from '../../static/icons/CircleLogoIcon';

export const Footer = () => {
  return (
    <Grid className='footer'>
      <Grid className='footer-container'>
        <CircleLogoIcon size={100} />
        <Typography className='text'>
          SOMOS UNA EMPRESA QUE BUSCA LA CONSTANTE INOVACION EN LA GESTION DE VIVIENDAS,
          NUESTRO PRINCIPAL OBJETIVO ES SIMPLIFICAR Y OPTIMIZAR LAS TAREAS ADMINISTRATIVAS EN CONDOMINIOS Y RECIDENCIAS 
          BUSCANDO UNA EXPERIENCIA EXTRAORDINARIA PARA NUESTROS CLIENTES.
        </Typography>
      </Grid>
    </Grid>
  );
};
