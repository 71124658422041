import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Home } from '../../views/comercial/home/Home';
import { Plans } from '../../views/comercial/plans/Plans';
import { KnowUs } from '../../views/comercial/knowUs/KnowUs';
import { Contact } from '../../views/comercial/contact/Contact';
import { Products } from '../../views/comercial/products/Products';

export const ComertialOutlet = () => {
  const location = useLocation();
  return (
    <Switch location={location} key={location.pathname}>
      <Route exact path='/home'>
        <Home />
      </Route>
      <Route path='/plans'>
        <Plans />
      </Route>
      <Route path='/products'>
        <Products />
      </Route>
      <Route path='/knowus'>
        <KnowUs />
      </Route>
      <Route path='/contact'>
        <Contact />
      </Route>
      <Route path='/*'>
        <Redirect to='/home' />
      </Route>
    </Switch>
  );
};
