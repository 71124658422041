import React from 'react';
import Grid from '@mui/material/Grid2';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Tooltip, Typography } from '@mui/material';

import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import FlareIcon from '@mui/icons-material/Flare';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import IosShareIcon from '@mui/icons-material/IosShare';
import CategoryIcon from '@mui/icons-material/Category';

import HousePart1 from '../../../static/img/casa1O.png'
import HousePart2 from '../../../static/img/casa2O.png'
import HousePart3 from '../../../static/img/casa3O.png'
import './styles/plans.scss';

export const Plans = () => {
  return (
    <Grid className='plans-main-container'>
      <Grid className='cards-container'>
        <Card className='card'>
          <CardHeader
            avatar={<TripOriginIcon />}
            title="EXCELSIOR"
            subheader=""
          />
          <CardMedia
            className='img'
            image={HousePart1}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              EXCELSIOR
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              PERMITENOS CONTARTE TODO LO IMPORTANTE
              <br/>
              <br/>
              $399.00 mxn / mes
              <ul style={{ paddingLeft: '15px' }}>
                <li>TODAS LAS FUNCIONALIDADES</li>
                <li>1 ADMINISTRADOR</li>
                <li>HASTA 15 VIVIENDAS</li>
                <li>USUARIOS ILIMITADOS</li>
                <li>SOPORTE</li>
                <Button sx={{ fontSize: '13px', ml: -2 }}>VER MÁS ...</Button>
              </ul> 
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid>
              <Button size="small" color='success' variant='outlined' sx={{ mr: '10px' }}>CONTRATAR</Button>
              <Tooltip title='GRATIS POR 14 DIAS' placement='top' arrow>
                <Button size="small">PRUEBA</Button>
              </Tooltip>
            </Grid>
            <Tooltip title='COMPARTIR' placement='top-end' arrow>
              <IconButton><IosShareIcon /></IconButton>
            </Tooltip>
          </CardActions>
        </Card>
        <Card className='card'>
          <CardHeader
            avatar={<FlareIcon />}
            title="MAJESTUOSO"
            subheader=""
          />
          <CardMedia
            className='img'
            image={HousePart2}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              MAJESTUOSO
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              PERMITENOS ACOMPAÑARTE EN TU CRECIMIENTO
              <br/>
              <br/>
              $699.00 mxn / mes
              <ul style={{ paddingLeft: '15px' }}>
                <li>TODAS LAS FUNCIONALIDADES</li>
                <li>HASTA 4 ADMINISTRADORES</li>
                <li>HASTA 70 VIVIENDAS</li>
                <li>USUARIOS ILIMITADOS</li>
                <li>SOPORTE</li>
                <Button sx={{ fontSize: '13px', ml: -2 }}>VER MÁS ...</Button>
              </ul> 
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid>
              <Button size="small" color='success' variant='outlined' sx={{ mr: '10px' }}>CONTRATAR</Button>
              <Tooltip title='GRATIS POR 14 DIAS' placement='top' arrow>
                <Button size="small">PRUEBA</Button>
              </Tooltip>
            </Grid>
            <Tooltip title='COMPARTIR' placement='top-end' arrow>
              <IconButton><IosShareIcon /></IconButton>
            </Tooltip>
          </CardActions>
        </Card>
        <Card className='card'>
          <CardHeader
            avatar={<LocalActivityIcon />}
            title="IMPERIAL"
            subheader=""
          />
          <CardMedia
            className='img'
            image={HousePart3}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              IMPERIAL
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              PERMITENOS MOSTRARTE QUE PODEMOS CRECER AUN MAS
              <br/>
              <br/>
              $1,499.00 mxn / mes
              <ul style={{ paddingLeft: '15px' }}>
                <li>TODAS LAS FUNCIONALIDADES</li>
                <li>ADMINISTRADORES SIN LIMITE</li>
                <li>HASTA 200 VIVIENDAS</li>
                <li>USUARIOS ILIMITADOS</li>
                <li>SOPORTE</li>
                <Button sx={{ fontSize: '13px', ml: -2 }}>VER MÁS ...</Button>
              </ul> 
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid>
              <Button size="small" color='success' variant='outlined' sx={{ mr: '10px' }}>CONTRATAR</Button>
              <Tooltip title='GRATIS POR 14 DIAS' placement='top' arrow>
                <Button size="small">PRUEBA</Button>
              </Tooltip>
            </Grid>
            <Tooltip title='COMPARTIR' placement='top-end' arrow>
              <IconButton><IosShareIcon /></IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      </Grid>
      <Grid className='other-plans'>
        <Typography>¿NECESITAS UN PLAN A TU MEDIDA?</Typography>
        <Card className='other-card'>
          <CardHeader
            avatar={<CategoryIcon />}
            title="EL PLAN JUSTO A TU MEDIDA"
            subheader=""
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              ESTA VEZ TÚ ELIGES
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              CREA EL PLAN A TÚ MEDIDA, CUANTANOS TUS NECESIDADES Y TE AYUDAMOS A RESOLVERLAS, TODAS NUESTRAS HERRAMIENTAS A TU ALCANCE Y EL LIMITE LO DETERMINAS TÚ
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex' }}>
            <Button size="small" variant='outlined' className='contact-btn'>CONSULTAR</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
