import React from 'react';
import Grid from '@mui/material/Grid2';
import { LogoIcon } from '../../static/icons/LogoIcon';
import './auth.scss';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from '@mui/material';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export const PasswordReset = () => {
  return (
    <Grid className='pass-reset-main-container'>
      <Grid className='main-password-reset'>
        <Grid className='password-reset-logo'>
          <LogoIcon size={110} />
        </Grid>
        <Card className='reset-password-form'>
          <CardHeader
            sx={{ ml: 4 }}
            avatar={
              <LockOpenOutlinedIcon />
            }
            title={<Typography sx={{ fontWeight: 500 }}>¿Problemas para iniciar sesión?</Typography>}
          />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', width: '300px', m: 'auto' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', justifyContent: 'center' }}>
              Por favor ingresa tu Email y a continuación te enviaremos un link para ayudarte a recuperar tu cuenta.
            </Typography>
            <TextField 
              label='Email'
              sx={{ width: '100%', mt: 2.5 }}
              size='small' 
              type='email'
              autoComplete='off'
            />
            <Button variant='contained' sx={{ mt: 2, height: '30px', borderRadius: 2, mb: 4, backgroundColor: 'primary.main' }}>Recuperar Cuenta</Button>
            <Divider variant='fullWidth'>O</Divider>
            <Typography sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
              <a 
                href='/accounts/register' 
                style={{ textDecoration: 'none', fontSize: '15px', cursor: 'pointer', color: '#363636', display: 'flex' }}
              >
                <AddCircleOutlineOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                Crear cuenta
              </a>
            </Typography>
          </CardContent>
          <CardActions>
            <a href='/auth' style={{ width: '100%' }}>
              <Button sx={{ backgroundColor: '#424242', m: -1, borderRadius: 0, width: '105%', color: '#ededed', height: '40px', fontSize: '13px' }}>
                Volver a inicio de sesion
              </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
