import React from 'react'
import Grid from '@mui/material/Grid2';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import video1Src from '../../../static/videos/fondo1.mp4';
import video2Src from '../../../static/videos/fondo2.mp4';
import { Button, Typography } from '@mui/material';
import './styles/home.scss'

export const Home = () => {

  return (
    <Grid className='main-container'>
      <Grid className='first-grid'>
        <video className="video-background" autoPlay loop muted src={video1Src}>
          Your browser does not support the video tag.
        </video>
        <Typography className='first-grid-text'>
          <h3>¿ESTAS LISTO?</h3>
          <h1>
            TRANQUILIDAD Y SEGURIDAD ES UNA REALIDAD
          </h1>
          <Button type='button' variant='outlined'>MAS INFORMACIÓN</Button>
        </Typography>
        <KeyboardDoubleArrowDownIcon className='arrow' />
      </Grid>
      <Grid className='second-grid'>
        <Typography className='second-grid-text'>
          <h3>SOLO LO MEJOR</h3>
          <h1>
            INOVACION CONSTANTE PARA LA ADMINISTRACION INTELIGENTE
          </h1>
          <Button type='button' variant='outlined'>MAS INFORMACIÓN</Button>
        </Typography>
      </Grid>
      <Grid className='third-grid'>
        <video className="video2-background" autoPlay loop muted src={video2Src}>
          Your browser does not support the video tag.
        </video>
        <Grid className='overlay'>

        </Grid>
        <Typography className='third-grid-text'>
          <h3>MENOS ES MÀS</h3>
          <h1>
            LA ADMINISTRACION NUNCA FUE TAN FACIL
          </h1>
          <Button type='button' variant='outlined'>MAS INFORMACIÓN</Button>
        </Typography>
      </Grid>
    </Grid>
  );
};
