import React from 'react';

interface Props {
  size?: number;
}

export const LogoIcon = ({ size = 155 }: Props) => {
  return (
    <svg id="effects" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.5 25.71" width={size} fill='white'>
      <path d="M45.28,4.35h-15.39"/>
      <path d="M61.37,4.35h-9.79"/>
      <path d="M74,4.35h-6.85"/>
      <g>
        <path d="M4.5,14.5v3.36c0,.18-.15.33-.33.33h-1.58c-.18,0-.33-.15-.33-.33V7.63h5.2c1.71,0,3.15,1.3,3.14,3.27,0,1.35-.67,2.38-1.64,2.9-.17.09-.25.3-.15.47l2.01,3.42c.13.22-.03.5-.29.5h-1.75c-.12,0-.24-.07-.3-.18l-1.87-3.66c-.06-.11-.17-.18-.3-.18h-1.48c-.18,0-.33.15-.33.33ZM7.27,12.31c.6,0,1.15-.5,1.15-1.42,0-.9-.54-1.41-1.15-1.41h-2.44c-.18,0-.33.15-.33.33v2.16c0,.18.15.33.33.33h2.44Z"/>
        <path d="M18.53,18.19h-5.79V7.63h5.64c1.03,0,1.86.83,1.86,1.86h0s-5.25,0-5.25,0v2.24h4.66v1.86h-4.66v2.74h5.4c0,1.03-.83,1.86-1.86,1.86Z"/>
        <path d="M27.35,7.41c1.35,0,2.87.51,4.16,1.5l-.58,1.59c-1.12-.72-2.36-1.11-3.45-1.11-1.85,0-3.18,1.3-3.18,3.51s1.33,3.52,3.18,3.52c1.1,0,2.36-.4,3.49-1.14l.58,1.58c-1.3,1-2.85,1.53-4.21,1.53-3.22,0-5.35-2.46-5.35-5.49s2.13-5.5,5.35-5.49h0Z"/>
        <path d="M33.68,17.07v-8.31c0-.62.5-1.12,1.12-1.12h0c.62,0,1.12.5,1.12,1.12v8.31c0,.62-.5,1.12-1.12,1.12h0c-.62,0-1.12-.5-1.12-1.12Z"/>
        <path d="M42.66,7.63c3.14,0,5.21,2.24,5.21,5.28s-2.07,5.28-5.21,5.28h-4.23V7.63h4.23ZM42.48,16.33c1.9,0,3.1-1.34,3.1-3.42s-1.21-3.42-3.1-3.42h-1.8v6.84h1.8Z"/>
        <path d="M55.94,18.19h-5.79V7.63h5.64c1.03,0,1.86.83,1.86,1.86h0s-5.25,0-5.25,0v2.24h4.66v1.86h-4.66v2.74h5.4c0,1.03-.83,1.86-1.86,1.86Z"/>
        <path d="M68.93,18.19h-2.01l-5.07-6.91v6.91h-2.19V7.63h2.01l5.07,6.97v-4.78c0-1.21.98-2.19,2.19-2.19h0v10.56Z"/>
        <path d="M77.67,10.26h0c-.63-.51-1.53-.88-2.64-.88-1.01,0-1.7.31-1.74,1.21-.03.59.27.8,2.12,1.29,1.59.43,3.68.93,3.7,3.21.02,2.37-2.27,3.3-4.15,3.3-1.61,0-3.37-.68-4.25-1.81l1.27-1.42c.66.81,1.9,1.28,2.99,1.27,1.16,0,1.83-.52,1.84-1.27.01-.79-.63-.95-2.11-1.36-2.52-.7-3.71-1.23-3.72-3.11,0-2.39,1.96-3.29,4.07-3.29.67,0,1.29.09,1.85.26,1.11.32,1.5,1.69.78,2.59Z"/>
        <path d="M84.04,14.38l-3.91-6.75h0c1.56,0,2.99.84,3.75,2.2l1.31,2.35M85.19,12.18l2.54-4.55h2.52s-3.97,6.75-3.97,6.75v2.75c0,.59-.48,1.07-1.07,1.07h-1.18v-3.81"/>
        <path d="M97.8,10.26h0c-.63-.51-1.53-.88-2.64-.88-1.01,0-1.7.31-1.74,1.21-.03.59.27.8,2.12,1.29,1.59.43,3.68.93,3.7,3.21.02,2.37-2.27,3.3-4.15,3.3-1.61,0-3.37-.68-4.25-1.81l1.27-1.42c.66.81,1.9,1.28,2.99,1.27,1.16,0,1.83-.52,1.84-1.27.01-.79-.63-.95-2.11-1.36-2.52-.7-3.71-1.23-3.72-3.11,0-2.39,1.96-3.29,4.07-3.29.67,0,1.29.09,1.85.26,1.11.32,1.5,1.69.78,2.59Z"/>
      </g>
      <polyline points="72.5 .35 76.5 4.35 101 4.35"/>
      <polyline points="0 4.35 25.5 4.35 29.5 .35"/>
      <polyline points="73.5 25.35 77.5 21.35 101.5 21.35"/>
      <polyline points=".5 21.35 24.5 21.35 28.5 25.35"/>
      <path d="M31,21.85h1-1Z"/>
      <circle cx="28.5" cy="21.35" r=".5"/>
      <circle cx="73.5" cy="21.35" r=".5"/>
      <circle cx="31.5" cy="21.35" r=".5"/>
      <circle cx="70.5" cy="21.35" r=".5"/>
      <circle cx="34.5" cy="21.35" r=".5"/>
      <circle cx="67.5" cy="21.35" r=".5"/>
      <circle cx="37.5" cy="21.35" r=".5"/>
      <circle cx="64.5" cy="21.35" r=".5"/>
      <circle cx="41.15" cy="21.52" r=".67"/>
      <circle cx="60.9" cy="21.62" r=".67"/>
      <circle cx="46.12" cy="21.15" r=".88"/>
      <circle cx="55.91" cy="21.36" r=".88"/>
      <circle cx="50.91" cy="21.36" r=".88"/>
    </svg>
  );
};
