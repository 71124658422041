import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import './styles/products.scss'


export const Products = () => {
  return (
    <Grid className='products-main-container'>
      <Typography variant='h3'>COMMING SOON</Typography>
    </Grid>
  );
};
