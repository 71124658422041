import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { ApplicationOutlet } from '../../routes/applicationRoutes/ApplicationOutlet';
import { ApplicationFooter, ConfigutationsDrawer, NavMenu } from './components';
import { ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from '../../theme/mainTheme';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setThemeAspect } from '../../redux/themeSlice';

export const MainApplicationLayout = () => {
  const [confOpen, setConfOpen] = useState(false);
  const { aspect } = useAppSelector(state => state.theme);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const dispatch = useAppDispatch();
  const themeLocalStorage = localStorage.getItem('recidensysUserThemePreferences');
  if (themeLocalStorage) {
    const userTheme = JSON.parse(themeLocalStorage);
    dispatch(setThemeAspect(userTheme?.aspect));
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <ThemeProvider theme={!aspect ? lightTheme : darkTheme}>
      <Grid 
        sx={{ 
          width: '100vw', 
          height: '100vh', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: 'background.default',
          border: 'none',
        }}
      >
        <NavMenu setConfOpen={setConfOpen} open={navMenuOpen} setNavMenuOpen={setNavMenuOpen} />
        <Grid 
          sx={{ 
            height: 'calc(100vh - 115px)', 
            width: windowDimensions.width >= 600 ? 'calc(100vw - 49px)' : '100%', 
            ml: windowDimensions.width >= 600 ? '49px' : 0, 
            mt: '45px',
          }}
          onClick={() => setNavMenuOpen(false)}
        >
          <ApplicationOutlet />
        </Grid>
        <ApplicationFooter />
        <ConfigutationsDrawer open={confOpen} setConfOpen={setConfOpen} />
      </Grid>
    </ThemeProvider>
  );
};