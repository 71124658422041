import React from 'react';
import Grid from '@mui/material/Grid2';

export const ApplicationFooter = () => {
  return (
    <Grid sx={{ height: '70px', width: '100%', backgroundColor: 'primary.light' }}>

    </Grid>
  );
};
