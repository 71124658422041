import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { ComertialOutlet } from '../../routes/comercialRoutes/ComertialOutlet';
import { NavBar } from './NavBar';
import { Footer } from './Footer';
import './styles/main.scss';
import { SearchNavbar } from './SearchNavbar';

export const MainComercialLayout = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <Grid sx={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <NavBar setSearchOpen={setSearchOpen} />
      <Grid className='main-container'>
        <ComertialOutlet />
      </Grid>
      <Footer />
      <SearchNavbar open={searchOpen} setSearchOpen={setSearchOpen} />
    </Grid>
  );
};
