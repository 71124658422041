import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LogoIcon } from '../../../static/icons/LogoIcon';
import Grid from '@mui/material/Grid2';
import { Collapse, Tooltip } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import PortraitIcon from '@mui/icons-material/Portrait';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { ListItemComponent } from './navMenuComponents';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PaidIcon from '@mui/icons-material/Paid';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 297;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

interface Props {
  setConfOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  setNavMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export  const NavMenu = ({ setConfOpen, open, setNavMenuOpen }: Props) => {
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setNavMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setNavMenuOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar 
        position="fixed" 
        open={open} 
        sx={{ 
          backgroundColor: 'primary.dark', 
          height: '45px', 
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: 5,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              handleDrawerOpen();
              setConfOpen(false);
            }}
            edge="start"
            sx={[
              {
                marginRight: 4,
                pl: '1%',
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <LogoIcon size={100} />
        </Toolbar>
        <Collapse in={!open}>
          <Grid sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
            <Typography sx={{ mr: 2 }}>Carlos Yair</Typography>
            <Tooltip title='Configuración'>
              <IconButton onClick={() => setConfOpen(state => !state)}>
                <TuneOutlinedIcon sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Log out'>
              <IconButton>
                <LogoutOutlinedIcon sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Collapse>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ mt: -2.5, alignItems: 'center' }}>
          <Grid sx={{ height: 55, display: 'flex', alignItems: 'center', left: 7, pt: 2.5, position: 'absolute' }}>
            <PortraitIcon sx={{ mr: .7, fontSize: '35px' }} />
            Carlos Yair Larracilla Espinosa
          </Grid>
          <IconButton onClick={handleDrawerClose} sx={{ mt: 2.5 }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Grid sx={{ position: 'absolute', mt: '45px', width: drawerWidth }}>
          <List>
            <ListItemComponent 
              isOpen={open} 
              key='comunicacion' 
              label='Comunicación y Notificaciones'
              icon={<NotificationsIcon />}
              onClick={handleDrawerClose}
            />
            <ListItemComponent 
              isOpen={open} 
              key='AdministracionGral' 
              label='Administración General'
              icon={<AutoAwesomeMotionIcon />}
              onClick={handleDrawerClose}
            />
            <ListItemComponent 
              isOpen={open} 
              key='propiedades' 
              label='Propiedades y Unidades'
              icon={<ApartmentIcon />}
              onClick={handleDrawerClose}
            />
            <ListItemComponent 
              isOpen={open} 
              key='pagos' 
              label='Pagos y Finanzas'
              icon={<PaidIcon />}
              onClick={handleDrawerClose}
            />
            <ListItemComponent 
              isOpen={open} 
              key='incidencias' 
              label='Incidencias y Mantenimiento'
              icon={<BuildCircleIcon />}
              onClick={handleDrawerClose}
            />
            <ListItemComponent 
              isOpen={open} 
              key='docs' 
              label='Documentación y Anuncios'
              icon={<NewspaperIcon />}
              onClick={handleDrawerClose}
            />
            <ListItemComponent 
              isOpen={open} 
              key='gestion' 
              label='Gestión'
              icon={<AdminPanelSettingsIcon />}
              onClick={handleDrawerClose}
            />
          </List>
          <Divider />
          <Collapse in={open}>
            <List>
              <ListItemComponent 
                isOpen={open} 
                key='configuracion' 
                label='Configuración'
                icon={<TuneOutlinedIcon />}
                onClick={() => {
                  handleDrawerClose();
                  setConfOpen(state => !state);
                }}
              />
              <ListItemComponent 
                isOpen={open} 
                key='logout' 
                label='Log out'
                icon={<LogoutOutlinedIcon />}
                onClick={handleDrawerClose}
              />
            </List>
          </Collapse>
        </Grid>
      </Drawer>
    </Box>
  );
}